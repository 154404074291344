import React, { useContext } from "react"
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next';
import flatten from 'flat';

import PropTypes from "prop-types"

import './header.scss';
import { themeContext } from "../../stateProvider";



const Header = ({ onScrollDownClick }) => {
  const { t, i18n } = useTranslation();
  const { state: themeState } = useContext(themeContext);

  return (
    <div className={`header ${themeState.theme}`}>
      <div className="logo__div">
      <svg 
        className="logo__svg"
        width="100%" 
        height="auto" 
        version="1.1" 
        viewBox="0 0 210 160" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="filter25068" x="-.035469" y="-.074224" width="1.0709" height="1.1484" colorInterpolationFilters="sRGB">
            <feGaussianBlur stdDeviation="1.26078"/>
          </filter>
        </defs>
        <g 
          className='logo__svg__g-Main' 
          transform="translate(0,-137)"
        >
            <g
              className="logo__svg__g-Main__g-stayHexes"
              stroke="#00baff"
              strokeLinejoin="round"
              strokeOpacity=".02"
              opacity="0"
            >
              <path d="m24.177 167.03-0.95774-0.58097v-1.162l0.95774-0.58098 0.95774 0.58098v1.162z" fill="#cb047a" />
              <path d="m2.1698 188.09-1.6552-0.98254v-1.9651l1.6552-0.98255 1.6552 0.98255v1.9651z" fill="#cb047a" />
              <path d="m40.59 163.99-1.2802-0.76665v-1.5333l1.2802-0.76664 1.2802 0.76664v1.5333z" fill="#cb047a" />
              <path d="m31.105 172.79-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#a5228f" />
              <path d="m20.554 181.25-1.2802-0.76665v-1.5333l1.2802-0.76664 1.2802 0.76664v1.5333z" fill="#f8a516" />
              <path d="m53.379 163.24-1.2802-0.76665v-1.5333l1.2802-0.76665 1.2802 0.76665v1.5333z" fill="#f8a516" />
              <path d="m41.819 176.62-3.6858-2.1537v-4.3074l3.6858-2.1537 3.6858 2.1537v4.3074z" fill="#cb047a" />
              <path d="m30.785 180.78-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#ffb43f" />
              <path d="m21.193 193.27-2.7745-1.6282-1e-6 -3.2565 2.7745-1.6283 2.7745 1.6283v3.2565z" fill="#3d8dff" />
              <path d="m15.545 199.96-2.3476-1.3821-1e-6 -2.7642 2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#ffb43f" />
              <path d="m8.2978 203.25-1.2802-0.76666v-1.5333l1.2802-0.76666 1.2802 0.76666v1.5333z" fill="#3d8dff" />
              <path d="m51.567 173.37-1.2802-0.76665v-1.5333l1.2802-0.76665 1.2802 0.76665v1.5333z" fill="#f8a516" />
              <path d="m41.601 185.79-3.0398-1.7812v-3.5624l3.0398-1.7812 3.0398 1.7812v3.5624z" fill="#ffb43f" />
              <path d="m31.591 187.7-1.2337-0.73957v-1.4792l1.2337-0.73958 1.2337 0.73958v1.4792z" fill="#3196d9" />
              <path d="m17.357 206.3-1.2802-0.76666-2e-6 -1.5333 1.2802-0.76666 1.2802 0.76666v1.5333z" fill="#6d3797" />
              <path d="m66.2 177.96-3.7332-2.1813-1e-6 -4.3625 3.7332-2.1813 3.7332 2.1813v4.3625z" fill="#f24925" />
              <path d="m53.539 187.39-4.8199-2.8081-2e-6 -5.6161 4.8199-2.8081 4.8199 2.8081v5.6161z" fill="#a5228f" />
              <path d="m46.009 202.88-5.3495-3.1135v-6.227l5.3495-3.1135 5.3495 3.1135v6.227z" fill="#f8a516" />
              <path d="m32.064 213.97-1.2802-0.76665v-1.5333l1.2802-0.76664 1.2802 0.76664v1.5333z" fill="#180164" />
              <path d="m33.981 194.68-0.95774-0.58098v-1.1619l0.95774-0.58098 0.95774 0.58098v1.1619z" fill="#f8a516" />
              <path d="m32.597 202.14-1.2802-0.76666v-1.5333l1.2802-0.76665 1.2802 0.76665v1.5333z" fill="#6d3797" />
              <path d="m26.816 208.91-2.8552-1.6748v-3.3495l2.8552-1.6748 2.8552 1.6748v3.3495z" fill="#b70679" />
              <path d="m21.3 215.84-2.3476-1.3821-1e-6 -2.7642 2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#6d3797" />
              <path d="m77.742 182.22-5.2571-3.0602v-6.1205l5.2571-3.0602 5.2571 3.0602v6.1205z" fill="#e92330" />
              <path d="m68.856 193.47-6.3656-3.6995v-7.3991l6.3656-3.6996 6.3655 3.6996v7.3991z" fill="#e92330" />
              <path d="m61.333 203.8-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" fill="#f26722" />
              <path d="m41.571 214.69-3.5483-2.0747v-4.1493l3.5483-2.0747 3.5483 2.0747v4.1493z" fill="#253189" />
              <path d="m38.458 223.33-2.7745-1.6282-3e-6 -3.2565 2.7745-1.6282 2.7745 1.6282v3.2565z" fill="#cb047a" />
              <path d="m55.913 217.1-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" fill="#feb03e" />
              <path d="m49.866 226.18-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" fill="#f26722" />
              <path d="m40.516 232.73-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#6d3797" />
              <path d="m27.346 225.22-2.5322-1.4885-1e-6 -2.977 2.5322-1.4885 2.5322 1.4885v2.977z" fill="#3b7edd" />
              <path d="m21.513 230.65-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#3d8dff" />
              <path fill="#f7a619" d="m70.966 219.24-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" />
              <path fill="#f7a619" d="m64.601 230.34-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" />
              <path fill="#f7a619" d="m59.681 242.55-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" />
              <path d="m48.208 253-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3656 3.6995v7.3991z" fill="#cb047a" />
            </g>
          <path 
            d="m143.95 252.52-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3655 3.6996v7.3991z" 
            fill="#120359" 
            stroke="#00baff" 
            strokeLinejoin="round" 
            strokeOpacity=".2" 
            strokeWidth=".1"
          />
          <g 
            fill="#11299f" 
            stroke="#00baff" 
            strokeLinejoin="round" 
            strokeOpacity=".2" 
            strokeWidth=".1"
          >
            <path 
            d="m131.22 252.52-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" />
            <path 
            d="m137.58 241.42-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" />
            <path 
            d="m143.95 230.32-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" />
          </g>
          <g 
            stroke="#00baff" 
            strokeLinejoin="round" 
            strokeOpacity=".2" 
            strokeWidth=".1"
          >
            <path 
            d="m131.22 208.2-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" 
              fill="#e92330" />
            <path 
            d="m137.58 197.1-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" 
              fill="#f26721" />
            <path 
            d="m137.58 219.29-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" 
              fill="#171869" />
            <path 
            d="m143.95 208.2-6.3655-3.6996v-7.3991l6.3655-3.6996 6.3656 3.6996v7.3991z" 
              fill="#0a198f" />
            <path 
            d="m112.55 197.36-6.3655-3.6995v-7.3991l6.3655-3.6996 6.3655 3.6996v7.3991z" 
              fill="#ff8438" />
            <path 
            d="m118.91 186.26-6.3655-3.6995v-7.3991l6.3655-3.6996 6.3656 3.6996v7.3991z" 
              fill="#f7a516" />
            <path 
            d="m138.01 182.57-6.3656 3.6996-6.3655-3.6996v-7.399z" 
              fill="#ff8438" />
            <path 
            d="m156.68 193.4-6.3656 3.6996-6.3656-3.6996v-7.399z" 
              fill="#3d8dff" />
            <path 
            d="m156.68 208.12-6.387-3.6626 0.0213-7.3621 6.4082-3.6995z" 
              fill="#180164" />
            <path 
            d="m125.28 197.36-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#f8a516" />
            <path 
            d="m150.31 219.22-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#3b7edd" />
            <path 
            d="m156.68 230.25-6.387-3.6626 0.0213-7.3622 6.4082-3.6995z" 
              fill="#180164" />
            <path 
            d="m150.31 241.34-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" 
              fill="#0e2398" />
            <path 
            d="m156.68 252.37-6.387-3.6626 0.0213-7.3622 6.4082-3.6995z" 
              fill="#3b7edd" />
            <path 
            d="m143.91 259.69-0.0213-7.3622 6.3869-3.6626 6.4082 3.6995z" 
              fill="#371f8a" />
            <path 
            d="m137.58 263.62-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" 
              fill="#253189" />
            <path 
            d="m124.85 263.62-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#3b7edd" />
            <path 
            d="m124.85 270.94-0.0213-7.3622 6.3869-3.6626 6.4082 3.6995z" 
              fill="#180164" />
            <path 
            d="m118.49 274.71-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#1d0360" />
            <path 
            d="m112.12 263.62-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#6d3797" />
            <path 
            d="m105.76 282.04-0.0213-7.3622 6.3869-3.6626 6.4082 3.6995z" 
              fill="#0e2398" />
            <path 
            d="m67.563 252.52 6.3656-3.6996v-7.3991l-6.3656-3.6996-6.3656 3.6996v7.3991z" 
              fill="#a70878" />
            <path 
            d="m80.294 252.52 6.3656-3.6996v-7.3991l-6.3656-3.6996-6.3655 3.6996v7.3991z" 
              fill="#cb047a" />
            <path 
            d="m74.314 241.8 6.3656-3.6996v-7.3991l-6.3656-3.6996-6.3656 3.6996v7.3991z" 
              fill="#feb03e" />
            <path 
            d="m67.563 259.84 0.02137-7.3622-6.3869-3.6626-6.4082 3.6995z" 
              fill="#a90778" />
            <path 
            d="m73.928 263.62 6.3656-3.6996v-7.3991l-6.3656-3.6995-6.3655 3.6995v7.3991z" 
              fill="#770c5a" />
            <path 
            d="m86.659 263.62 6.3656-3.6996v-7.3991l-6.3656-3.6995-6.3656 3.6995v7.3991z" 
              fill="#640e4d" />
            <path 
            d="m86.659 270.94 0.02134-7.3622-6.3869-3.6626-6.4082 3.6995z" 
              fill="#f82e83" />
            <path 
            d="m93.025 274.71 6.3656-3.6996v-7.3991l-6.3656-3.6995-6.3656 3.6995v7.3991z" 
              fill="#6d0d52" />
            <path 
            d="m99.39 263.62 6.3655-3.6996 1e-5 -7.3991-6.3655-3.6995-6.3656 3.6995v7.3991z" 
              fill="#51103f" />
            <path 
            d="m105.76 282.04 0.0213-7.3622-6.3869-3.6626-6.4082 3.6995z" 
              fill="#6d3797" />
            <path 
            d="m105.76 274.71-6.3655-3.6996v-7.3991l6.3655-3.6995 6.3656 3.6995v7.3991z" 
              fill="#6d3797" />
            <path 
            d="m106.18 186.26-6.3655-3.6996v-7.3991l6.3655-3.6996 6.3656 3.6996v7.3991z" 
              fill="#fc5c4c" />
            <path 
            d="m99.816 197.36-6.3655-3.6995v-7.3991l6.3655-3.6996 6.3655 3.6996v7.3991z" 
              fill="#ffb43f" />
            <path 
            d="m93.45 208.46-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" 
              fill="#f82e83" />
            <path 
            d="m87.085 219.56-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" 
              fill="#f8a516" />
            <path 
            d="m80.719 230.66-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#f24925" />
            <path 
            d="m93.45 186.26-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3655 3.6996v7.3991z" 
              fill="#f26722" />
            <path 
            d="m87.085 197.36-6.3656-3.6995v-7.3991l6.3656-3.6996 6.3655 3.6996v7.3991z" 
              fill="#f24925" />
            <path 
            d="m80.719 208.46-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
              fill="#e92330" />
          </g>
            <g
              className='logo__svg__g-Main__g-blowawayHexes'
              stroke="#00baff"
              strokeLinejoin="round"
              strokeOpacity=".2"
              strokeWidth=".1"
            >
              <path d="m24.177 167.03-0.95774-0.58097v-1.162l0.95774-0.58098 0.95774 0.58098v1.162z" fill="#cb047a" />
              <path d="m2.1698 188.09-1.6552-0.98254v-1.9651l1.6552-0.98255 1.6552 0.98255v1.9651z" fill="#cb047a" />
              <path d="m40.59 163.99-1.2802-0.76665v-1.5333l1.2802-0.76664 1.2802 0.76664v1.5333z" fill="#cb047a" />
              <path d="m31.105 172.79-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#a5228f" />
              <path d="m20.554 181.25-1.2802-0.76665v-1.5333l1.2802-0.76664 1.2802 0.76664v1.5333z" fill="#f8a516" />
              <path d="m53.379 163.24-1.2802-0.76665v-1.5333l1.2802-0.76665 1.2802 0.76665v1.5333z" fill="#f8a516" />
              <path d="m41.819 176.62-3.6858-2.1537v-4.3074l3.6858-2.1537 3.6858 2.1537v4.3074z" fill="#cb047a" />
              <path d="m30.785 180.78-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#ffb43f" />
              <path d="m21.193 193.27-2.7745-1.6282-1e-6 -3.2565 2.7745-1.6283 2.7745 1.6283v3.2565z" fill="#3d8dff" />
              <path d="m15.545 199.96-2.3476-1.3821-1e-6 -2.7642 2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#ffb43f" />
              <path d="m8.2978 203.25-1.2802-0.76666v-1.5333l1.2802-0.76666 1.2802 0.76666v1.5333z" fill="#3d8dff" />
              <path d="m51.567 173.37-1.2802-0.76665v-1.5333l1.2802-0.76665 1.2802 0.76665v1.5333z" fill="#f8a516" />
              <path d="m41.601 185.79-3.0398-1.7812v-3.5624l3.0398-1.7812 3.0398 1.7812v3.5624z" fill="#ffb43f" />
              <path d="m31.591 187.7-1.2337-0.73957v-1.4792l1.2337-0.73958 1.2337 0.73958v1.4792z" fill="#3196d9" />
              <path d="m17.357 206.3-1.2802-0.76666-2e-6 -1.5333 1.2802-0.76666 1.2802 0.76666v1.5333z" fill="#6d3797" />
              <path d="m66.2 177.96-3.7332-2.1813-1e-6 -4.3625 3.7332-2.1813 3.7332 2.1813v4.3625z" fill="#f24925" />
              <path d="m53.539 187.39-4.8199-2.8081-2e-6 -5.6161 4.8199-2.8081 4.8199 2.8081v5.6161z" fill="#a5228f" />
              <path d="m46.009 202.88-5.3495-3.1135v-6.227l5.3495-3.1135 5.3495 3.1135v6.227z" fill="#f8a516" />
              <path d="m32.064 213.97-1.2802-0.76665v-1.5333l1.2802-0.76664 1.2802 0.76664v1.5333z" fill="#180164" />
              <path d="m33.981 194.68-0.95774-0.58098v-1.1619l0.95774-0.58098 0.95774 0.58098v1.1619z" fill="#f8a516" />
              <path d="m32.597 202.14-1.2802-0.76666v-1.5333l1.2802-0.76665 1.2802 0.76665v1.5333z" fill="#6d3797" />
              <path d="m26.816 208.91-2.8552-1.6748v-3.3495l2.8552-1.6748 2.8552 1.6748v3.3495z" fill="#b70679" />
              <path d="m21.3 215.84-2.3476-1.3821-1e-6 -2.7642 2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#6d3797" />
              <path d="m77.742 182.22-5.2571-3.0602v-6.1205l5.2571-3.0602 5.2571 3.0602v6.1205z" fill="#e92330" />
              <path d="m68.856 193.47-6.3656-3.6995v-7.3991l6.3656-3.6996 6.3655 3.6996v7.3991z" fill="#e92330" />
              <path d="m61.333 203.8-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" fill="#f26722" />
              <path d="m41.571 214.69-3.5483-2.0747v-4.1493l3.5483-2.0747 3.5483 2.0747v4.1493z" fill="#253189" />
              <path d="m38.458 223.33-2.7745-1.6282-3e-6 -3.2565 2.7745-1.6282 2.7745 1.6282v3.2565z" fill="#cb047a" />
              <path d="m55.913 217.1-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" fill="#feb03e" />
              <path d="m49.866 226.18-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" fill="#f26722" />
              <path d="m40.516 232.73-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#6d3797" />
              <path d="m27.346 225.22-2.5322-1.4885-1e-6 -2.977 2.5322-1.4885 2.5322 1.4885v2.977z" fill="#3b7edd" />
              <path d="m21.513 230.65-2.3476-1.3821v-2.7642l2.3476-1.3821 2.3476 1.3821v2.7642z" fill="#3d8dff" />
              <path fill="#f7a619" d="m70.966 219.24-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" />
              <path fill="#f7a619" d="m64.601 230.34-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" />
              <path fill="#f7a619" d="m59.681 242.55-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" />
              <path d="m48.208 253-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3656 3.6995v7.3991z" fill="#cb047a" />
            </g>
          <g 
            stroke="#00baff" 
            strokeLinejoin="round" 
            strokeOpacity=".2" 
            strokeWidth=".1"
          >
          <path 
          d="m93.025 252.52 6.3656-3.6996v-7.3991l-6.3656-3.6996-6.3656 3.6996v7.3991z" 
            fill="#b70679" />
          <path 
          d="m118.91 208.46-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3656 3.6995v7.3991z" 
            fill="#cb047a" />
          <path 
          d="m112.55 219.56-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3655 3.6995v7.3991z" 
            fill="#f82e83" />
          <path 
          d="m106.18 230.66-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3656 3.6995v7.3991z" 
            fill="#b70679" />
          <path 
          d="m99.816 241.76-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3655 3.6995v7.3991z" 
            fill="#b70679" />
          <path 
          d="m160.54 191.24 6.3655-3.6995 1e-5 -7.3991-6.3656-3.6996-6.3656 3.6996v7.3991z" 
            fill="#3b7edd" />
          <path 
          d="m106.18 208.46-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3656 3.6995v7.3991z" 
            fill="#f26722" />
          <path 
          d="m99.816 219.56-6.3655-3.6995v-7.3991l6.3655-3.6995 6.3655 3.6995v7.3991z" 
            fill="#f24925" />
          <path 
          d="m93.45 230.66-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" 
            fill="#f24925" />
          <path 
          d="m87.085 241.76-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" 
            fill="#ef017c" />
          <path 
          d="m167.07 211.98 6.3656-3.6995v-7.3991l-6.3656-3.6995-6.3656 3.6995v7.3991z" 
            fill="#3b7edd" />
          <path 
          d="m124.85 219.29-6.3655-3.6995v-7.3991l6.3655-3.6996 6.3655 3.6996v7.3991z" 
            fill="#30007a" />
          <path 
          d="m118.49 230.39-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3655 3.6995v7.3991z" 
            fill="#11299f" />
          <path 
          d="m112.12 241.49-6.3656-3.6995v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
            fill="#a5228f" />
          <path 
          d="m105.76 252.52-6.3655-3.6996v-7.3991l6.3655-3.6996 6.3656 3.6996v7.3991z" 
            fill="#cb047a" />
          <path 
          d="m131.22 230.32-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
            fill="#3d8dff" />
          <path 
          d="m124.85 241.42-6.3656-3.6996v-7.3991l6.3656-3.6995 6.3656 3.6995v7.3991z" 
            fill="#a5228f" />
          <path 
          d="m118.49 252.52-6.3656-3.6996v-7.3991l6.3656-3.6996 6.3656 3.6996v7.3991z" 
            fill="#253189" />
          </g>
          <path 
            className="logo__svg__g-Main__shadowZD"
            d="m70.572 241.01h65.705c16.871-13.711 4.7852-30.797-17.57-29.433-0.61492 0.0372-27.774 26.398-27.774 26.398l-6.4276-0.0666s2.9033-2.7652 3.2657-3.1146c4.1514-4.0045 30.028-28.948 30.506-28.983 35.488-2.5746 40.952 30.877 18 40.626h-71.511zm6.0956-34.919h31.612v5.307l-32.304 29.71-5.4036-0.0971 31.754-29.492h-31.754z" 
            fillOpacity=".52444" 
            filter="url(#filter25068)" 
          />
          <clipPath
            id="logo__svg__g-Main__pathZD-clipPath"
          >
            <path 
              d="m68.916 239.56h64.934c16.673-13.375 4.7291-30.041-17.364-28.711-0.60772 0.0363-27.448 25.751-27.448 25.751l-6.3522-0.065s2.8693-2.6974 3.2274-3.0383c4.1028-3.9063 29.675-28.238 30.148-28.272 35.072-2.5115 40.472 30.12 17.789 39.63h-70.673zm6.0241-34.063h31.241v5.1769l-31.925 28.981-5.3403-0.0947 31.381-28.769h-31.381z" 
            />
          </clipPath>
          <path 
            className="logo__svg__g-Main__pathZD"
            clipPath="url(#logo__svg__g-Main__pathZD-clipPath)"
            d="m 67.34122,208.04998 38.84035,-0.11716 c -6.314135,5.64668 -37.076488,34.83934 -37.076488,34.83934 32.344298,-1.98445 59.326608,2.521 66.270758,-2.09566 24.0454,-15.98607 -1.91833,-36.40391 -19.00545,-32.48019 l -26.571916,25.90793 -6.734669,5.7743" 
          />
        </g>
      </svg>
      </div>
      <div className="header__gistDiv">
        <h1 className='header__gistDiv__name'>
          {t("indexPage.header.h1")}
        </h1>
        <h2 className='header__gistDiv__whoami'>
          {t("indexPage.header.h2")}
        </h2>
        <Link
          className='header__gistDiv__aboutLink'
          to="/about/"
        >
          {t("indexPage.header.aboutMeLink")}
        </Link>
      </div>
      <button 
            className={`header__scrolldownBtn header__scrolldownBtn--${themeState.theme}`}
            onClick={() => onScrollDownClick()}
        >
        <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" 
        
          fill="whitesmoke" xmlns="http://www.w3.org/2000/svg">
            <path 
            
              fillRule="evenodd" 
              d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            <path 
            
              fillRule="evenodd" 
              d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
    </div>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
