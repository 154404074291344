import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from 'react-i18next';

import './toolbox.scss';

import '../styles/fadein.scss';
import useIntersectionObserver from "../hooks/useIntersectionObserver";

import Tool from "./tool";


const toolsProgramming = [
  {
    category: 'programming',
    name: 'JavaScript',
    img: 'js',
    description: ''
  },
  {
    category: 'programming',
    name: 'HTML',
    img: 'html',
    description: ''
  },
  {
    category: 'programming',
    name: 'CSS',
    img: 'css',
    description: ''
  },
  {
    category: 'programming',
    name: 'Python',
    img: 'python',
    description: ''
  },
  {
    category: 'programming',
    name: 'Kotlin',
    img: 'kotlin',
    description: ''
  },
  {
    category: 'programming',
    name: 'MongoDB',
    img: 'mongo',
    description: ''
  },
  {
    category: 'programming',
    name: 'MySQL',
    img: 'mysql',
    description: ''
  },
  {
    category: 'programming',
    name: 'Node.js',
    img: 'node',
    description: ''
  },
  {
    category: 'programming',
    name: 'Flask',
    img: 'flask',
    description: ''
  },
  {
    category: 'programming',
    name: 'Django',
    img: 'django',
    description: ''
  },
  {
    category: 'programming',
    name: 'NginX',
    img: 'nginx',
    description: ''
  },
  {
    category: 'programming',
    name: 'React.js',
    img: 'react',
    description: ''
  },
  {
    category: 'programming',
    name: 'Redux',
    img: 'redux',
    description: ''
  },
  {
    category: 'programming',
    name: 'Gatsby',
    img: 'gatsby',
    description: ''
  },
  {
    category: 'programming',
    name: 'Next.js',
    img: 'nextjs',
    description: ''
  },
  {
    category: 'programming',
    name: 'Vue.js',
    img: 'vue',
    description: ''
  },
  {
    category: 'programming',
    name: 'SASS',
    img: 'sass',
    description: ''
  }
];

const Toolbox = (props) => {
  const { t, i18n } = useTranslation();

  const divRef = useRef();
  const { hasEntered } = useIntersectionObserver(divRef);

  const subheading1Ref = useRef();
  const { hasEntered: hasSH1Entered } = useIntersectionObserver(subheading1Ref);

  const subheading2Ref = useRef();
  const { hasEntered: hasSH2Entered } = useIntersectionObserver(subheading2Ref);

  const subheading3Ref = useRef();
  const { hasEntered: hasSH3Entered } = useIntersectionObserver(subheading3Ref);

  const icons = useStaticQuery(graphql`
  {
    allFile(filter: { extension: { eq: "png" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`)

const toolsLanguages = [
  {
    category: 'languages',
    name: t("indexPage.toolbox.toolsLanguages.english.name"),
    level: t("indexPage.toolbox.toolsLanguages.english.level"),
    img: 'eng',
    description: ''
  },
  {
    category: 'languages',
    name: t("indexPage.toolbox.toolsLanguages.chinese.name"),
    level: t("indexPage.toolbox.toolsLanguages.chinese.level"),
    img: 'chinese',
    description: ''
  },
  {
    category: 'languages',
    name: t("indexPage.toolbox.toolsLanguages.russian.name"),
    level: t("indexPage.toolbox.toolsLanguages.russian.level"),
    img: 'russian',
    description: ''
  },
  {
    category: 'languages',
    name: t("indexPage.toolbox.toolsLanguages.french.name"),
    level: t("indexPage.toolbox.toolsLanguages.french.level"),
    img: 'french',
    description: ''
  },
  {
    category: 'languages',
    name: t("indexPage.toolbox.toolsLanguages.azeri.name"),
    level: t("indexPage.toolbox.toolsLanguages.azeri.level"),
    img: 'azeri',
    description: ''
  }
];
const toolsMedia = [
  {
    category: 'media',
    name: t("indexPage.toolbox.toolsMedia.mediaWriting.name"),
    img: 'mediawriting',
    description: ''
  },
  {
    category: 'media',
    name: t("indexPage.toolbox.toolsMedia.techWriting.name"),
    img: 'techwriting',
    description: ''
  },
  {
    category: 'media',
    name: t("indexPage.toolbox.toolsMedia.marketing.name"),
    img: 'marketing',
    description: ''
  },
];

  return (
    <div ref={divRef} className={`toolbox fade-in-div ${hasEntered ? 'is-visible' : ''}`}>
      <h2 className="toolbox__heading">
        {t("indexPage.toolbox.heading")}
      </h2>
      <div ref={subheading1Ref} className={`toolbox__subheading fade-in-div ${hasSH1Entered ? 'is-visible' : ''}`}>
        {t("indexPage.toolbox.programming")}
      </div>
      <div className="toolbox__toolsContainer">
        {
          toolsProgramming.map((tool) => (
            <Tool key={tool.name}>
              {
              icons.allFile.edges.find(n => n.node.name === tool.img)
              ?
              <img
                className='toolsContainer__tool__image'
                src={icons.allFile.edges.find(n => n.node.name === tool.img).node.publicURL}
                alt={''}
              />
              :
              null
              }
              <div
                className='toolsContainer__tool__name'
              >
                {tool.name}
              </div>
            </Tool>
          ))
        }
      </div>
      <div ref={subheading2Ref} className={`toolbox__subheading fade-in-div ${hasSH2Entered ? 'is-visible' : ''}`}>
        {t("indexPage.toolbox.languages")}
      </div>
      <div className="toolbox__toolsContainer">
        {
          toolsLanguages.map((tool) => (
            <Tool key={tool.name}>
              {
              icons.allFile.edges.find(n => n.node.name === tool.img)
              ?
              <img
                className='toolsContainer__tool__image'
                src={icons.allFile.edges.find(n => n.node.name === tool.img).node.publicURL}
                alt={''}
              />
              :
              null
              }
              <div
                className='toolsContainer__tool__name'
              >
                {tool.name}
              </div>
              <div className='toolsContainer__tool__languageLevel'>
                {tool.level}
              </div>
            </Tool>
          ))
        }
      </div>
      <div ref={subheading3Ref} className={`toolbox__subheading fade-in-div ${hasSH3Entered ? 'is-visible' : ''}`}>
        {t("indexPage.toolbox.media")}
      </div>
      <div className="toolbox__toolsContainer">
        {
          toolsMedia.map((tool) => (
            <Tool key={tool.name}>
              {
              icons.allFile.edges.find(n => n.node.name === tool.img)
              ?
              <img
                className='toolsContainer__tool__image'
                src={icons.allFile.edges.find(n => n.node.name === tool.img).node.publicURL}
                alt={''}
              />
              :
              null
              }
              <div
                className='toolsContainer__tool__name'
              >
                {tool.name}
              </div>
            </Tool>
          ))
        }
      </div>
    </div>
  )
}

export default Toolbox;