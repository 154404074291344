import React, { useState } from "react";
import { Link } from "gatsby";

import './casePreview.scss';
import '../styles/fadein.scss';
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";

const CasePreview = ({ portfolioCase, locale }) => {

    return (
    <div className="casePreview">
      <h3>{portfolioCase.node.frontmatter.title}</h3>
      <p>{portfolioCase.node.excerpt}</p>
      <Link to={`${portfolioCase.node.frontmatter.path}`}>link</Link>
    </div>
  )
}

export default CasePreview;

export const CasePreviewWithEnterance = ({ portfolioCase, locale }) => {
  const { t, i18n } = useTranslation();
  
  const divRef = React.useRef();
  const { hasEntered } = useIntersectionObserver(divRef);

  return (
  <div 
    ref={divRef} 
    className={`casePreview fade-in-div ${hasEntered ? 'is-visible' : ''}`}
  >
      {
      portfolioCase.node.frontmatter.frontPageImg
      ?
      <div 
        className={`casePreview__frontpageImg__container`}
      >
        <img
          className={`casePreview__frontpageImg__img`}
          src={portfolioCase.node.frontmatter.frontPageImg}
        />
      </div>
      :
      null
      }
      <h3>{portfolioCase.node.frontmatter.title}</h3>
      <p className='casePreview__description'>{portfolioCase.node.frontmatter.description ? portfolioCase.node.frontmatter.description : portfolioCase.node.excerpt}</p>
    <Link className='casePreview__link' to={`${portfolioCase.node.frontmatter.path}`}>{t("casePreview.link")}</Link>
  </div>
)
}