import React from "react";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';

import PropTypes from "prop-types"

import './tool.scss';

import '../styles/fadein.scss';
import useIntersectionObserver from "../hooks/useIntersectionObserver";

const Tool = (props) => {
  const divRef = React.useRef();
  const { hasEntered } = useIntersectionObserver(divRef)

  return (
    <div 
      ref={divRef} 
      className={`toolsContainer__tool fade-in-div ${hasEntered ? 'is-visible' : ''}`}
    >
      {props.children}
    </div>
  )
}

export default Tool;