//import Image from "../components/image"
import React, { useRef } from "react";
import { graphql } from "gatsby";
import { useTranslation } from 'react-i18next';

// Layout & SEO
import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import Header from '../components/header';
import CasesHighlights from '../components/casesHighlights';
import BlogHighlights from "../components/blogHighlights";
import Toolbox from "../components/toolbox";
import ContactForm from "../components/contactForm";

const IndexPage = ({ data }) => {
  const { t, i18n } = useTranslation();
  const mainContentRef = useRef(null);

  const handleScrollDown = () => {
    mainContentRef.current.scrollIntoView({
        behavior: 'smooth'
    })
  }

  const dataLocale = data.allMarkdownRemark.edges.filter((edge) => {
    return i18n.language.includes(edge.node.frontmatter.language);
  })

  let casesData = dataLocale.filter(edge => edge.node.frontmatter.path.includes('portfolio/'));

  if(casesData && casesData.length > 5) {
    casesData = casesData.filter(edge => edge.node.frontmatter.frontPage);
  }

  let blogData = dataLocale.filter(edge => edge.node.frontmatter.path.includes('blog/'));

  if(blogData && blogData.length > 5) {
    blogData = blogData.filter(edge => edge.node.frontmatter.frontPage);
  }


  return (
  <Layout>
    <SEO title="Home" />
      <Header
        onScrollDownClick={handleScrollDown}
      />
      <div 
        style={{scrollMargin: '0'}}
        ref={mainContentRef}
      />      
      {
        casesData && casesData.length > 0
        ?
        <CasesHighlights 
          data={casesData}
        />
        :
        null
      }
      <Toolbox />
      {
        blogData && blogData.length > 0
        ?
        <BlogHighlights 
          data={blogData}
        />
        :
        null
      }
      <ContactForm />
  </Layout>
  )
}

export default IndexPage;

export const query = graphql`
query {
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        excerpt
        id
        frontmatter {
          language
          frontPage
          date
          path
          title
          description
          roles
          frontPageImg
        }
        timeToRead
      }
    }
  }
}
`