import React, { useRef } from "react";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';

import { BlogpostPreviewWithEnterance } from "./blogpostPreview";

import '../styles/fadein.scss';
import useIntersectionObserver from "../hooks/useIntersectionObserver";

import './blogHighlights.scss';

const BlogHighlights = ({ data }) => {
  const { t, i18n } = useTranslation();
  const divRef = useRef();
  const { hasEntered } = useIntersectionObserver(divRef)

  return (
    <div ref={divRef} className={`blogHighlights fade-in-div ${hasEntered ? 'is-visible' : ''}`}>
      <h2 className="blogHighlights__heading">{t("indexPage.blogHighlights.heading")}</h2>
      <div className="blogHighlights__lead">{t("indexPage.blogHighlights.lead")}</div>
      <div className="blogHighlights__link"><Link to='/blog/'>{t("indexPage.blogHighlights.link")}</Link></div>
      {
        data.map(edge => (
            <BlogpostPreviewWithEnterance
              key={edge.node.id}
              blogpost={edge}
              locale={i18n.language}
            />
        ))
      }
    </div>
  )
}

export default BlogHighlights;