import React, { useRef } from "react";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';
import flatten from 'flat';

import PropTypes from "prop-types"

import CasePreview, { CasePreviewWithEnterance } from './casePreview';

import '../styles/fadein.scss';
import useIntersectionObserver from "../hooks/useIntersectionObserver";

import './casesHighlights.scss';

const CasesHighlights = ({ data }) => {
  const { t, i18n } = useTranslation();
  const divRef = useRef();
  const { hasEntered } = useIntersectionObserver(divRef)


  return (
    <div ref={divRef} className={`casesHighlights fade-in-div ${hasEntered ? 'is-visible' : ''}`}>
      <h2 className="casesHighlights__heading">{t("indexPage.portfolioHighlights.heading")}</h2>
      <div className="casesHighlights__lead">{t("indexPage.portfolioHighlights.lead")}</div>
      <div className="casesHighlights__link"><Link to='/portfolio/'>{t("indexPage.portfolioHighlights.link")}</Link></div>
      {
        data.map(edge => (
          <CasePreviewWithEnterance
            key={edge.node.id}
            portfolioCase={edge}
            locale={i18n.language}
          />
        ))
      }
    </div>
  )
}

export default CasesHighlights;